// Utils
import { get } from 'lodash'

export default {
  name: 'VuetifyTabs',
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    selectedTab: {
      type: Number,
      default: 0
    },
    /**
     * https://vuetifyjs.com/en/components/tabs/#tabs
     */
    tabsVOptions: {
      default() {
        return {
          centered: false,
          'icons-and-text': false,
          height: 36,
          'slider-size': 0
        }
      },
      type: Object
    }
  },
  data() {
    return {
      /**
       * Uso "tabNav" y "tabItem" para evitar el comportamiento natural de las pestañas
       */
      tabNav: this.selectedTab, // Marca la pestaña seleccionada
      tabItem: this.selectedTab, // Marca el contenido de la pestaña seleccionada
      itemsData: {} // Para almacenar los datos entre pestañas que nos puedan interesar
    }
  },
  mounted() {
    this.$on('onChangeItemsData', this.handleChangeItemsData)
    this.$on('onChangeTab', this.handleChangeTab)
  },
  destroyed() {
    this.itemsData = {}
  },
  methods: {
    /**
     * Controlamos este evento para que las pestañas (navegación)
     * no tengan su comportamiento habitual y cambien
     * cuando se pulsa en ellas, sino cuando nosotros deseamos
     */
    handleChangeTabNav() {
      this.$nextTick(() => {
        this.tabNav = this.tabItem
      })
      return
    },
    /**
     * Evento lanzado cuando pulsamos sobre una pestaña (navegación),
     * en este caso usamos la referencia para llamar a un método
     * propio de cada componente del "tabs" para determinar
     * si podemos pasar a la pestaña pulsada
     *
     * @param {Number} tab - identificador de tab
     */
    handleClickTabNav(tab) {
      // Obtengo el componente actual cargado y si este posee
      // alguna función que se deba cumplir para pasar
      // a la siguiente pestaña
      const component = get(this.$refs, `tab-item-component-${this.tabItem}`, false)

      if (
        component &&
        Array.isArray(component) &&
        component.length > 0 &&
        typeof component[0].fnToChangeTab === 'function'
      ) {
        component[0].fnToChangeTab(tab)
      } else {
        this.handleChangeTab(tab)
      }
    },
    /**
     * Almacenamos en "itemsData" aquellos datos que me interesan
     * almacenar entre componentes que forman este "VuetiyTabs"
     *
     * @param {Number} tab - pestaña
     * @param {number | string | object | array} response - respuesta que nos trae la pestaña
     */
    handleChangeItemsData(tab, response) {
      this.$set(this.itemsData, tab, response)
    },
    /**
     * Modifica las 2 (todas) variables que tenemos para
     * gestionar las pestañas
     *
     * @param {Number} tab - número de la pestaña
     */
    handleChangeTab(tab) {
      this.tabItem = tab // Marca el contenido de la pestaña seleccionada
      this.tabNav = tab // Marca la pestaña seleccionada de la nagevación
    }
  }
}
