export default {
  name: 'YoutubeVideoPlayer',
  props: {
    url: {
      required: true,
      type: String,
      default: null
    }
  }
}
