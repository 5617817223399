// Components
import draggable from 'vuedraggable/src/vuedraggable'
// Utils
import { cloneDeep, isNil } from 'lodash'

export default {
  name: 'DraggableList',
  components: { draggable },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    handle: {
      type: String,
      default: '.handle'
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    tag: {
      type: String,
      default: 'ul'
    },
    // Como se muestran los elementos
    column: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      parsedItems: []
    }
  },
  watch: {
    items: {
      handler(newValue, oldValue) {
        let newValueElementsLength = 0
        let oldValueElementsLength = 0
        if (Array.isArray(newValue)) {
          newValueElementsLength = newValue.length
        } else if (!isNil(newValue) && typeof newValue === 'object') {
          newValueElementsLength = Object.keys(newValue).length
        }
        if (Array.isArray(oldValue)) {
          oldValueElementsLength = oldValue.length
        } else if (!isNil(oldValue) && typeof oldValue === 'object') {
          oldValueElementsLength = Object.keys(oldValue).length
        }

        if (
          (!isNil(newValue) && isNil(oldValue)) ||
          (!isNil(newValue) &&
            !isNil(oldValue) &&
            newValueElementsLength !== oldValueElementsLength)
        ) {
          // Parseamos para listar
          this.setParsedItems(newValue)
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * Handle when the items are reordered
     */
    handleChange() {
      this.$emit('onChange', this.items)
    },
    /**
     * Si los items llegan en forma de Objeto, los convertimos
     * a array, ya que el componente "draggable" así los requiere
     *
     * @param {array | object} - elementos que deseamos parsear
     * @return {array} - listado de elementos parseados
     */
    setParsedItems(value) {
      if (Array.isArray(value)) {
        this.parsedItems = cloneDeep(value)
      } else if (typeof value === 'object' && Object.keys(value).length > 0) {
        this.parsedItems = Object.values(value).reduce((accItems, item) => {
          accItems.push(item)
          return accItems
        }, [])
      }
    }
  }
}
