// Vuex
import { mapState } from 'vuex'
// Components
import YoutubeVideoPlayer from '@/components/ui/YoutubeVideoPlayer'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Utils
import { isYoutubeUrl } from '@/utils'
// Filters
import { sanitizeHtmlContent } from '@/filters'

export default {
  name: 'SectionInformationBox',
  components: { YoutubeVideoPlayer },
  mixins: [uiMixin],
  props: {
    media: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    buttonLabel: {
      type: String,
      default: null
    },
    link: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapState('app', ['extraSmallDevice']),
    /**
     * Es un video de Youtube?
     *
     * @return {boolean}
     */
    isYoutubeVideo() {
      return isYoutubeUrl(this.media)
    },
    /**
     * Anchura máxima de imagen
     *
     * @return {string}
     */
    maxWidth() {
      return this.extraSmallDevice ? 150 : 250
    },
    /**
     * Altura máximo de imagen
     *
     * @return {string}
     */
    maxHeight() {
      return this.extraSmallDevice ? 150 : 250
    },
    /**
     * Descripción "limpiada"
     *
     * @return {string}
     */
    sanitizedDescription() {
      return sanitizeHtmlContent(this.description)
    }
  },
  methods: {
    /**
     * Click on Button
     */
    handleClickButton() {
      this.$emit('onClickButton')
    },
    /**
     * Click on Link
     */
    handleClickLink() {
      this.routerPushTo({
        path: this.link.url
      })
    }
  }
}
