// Request
import { doRequest } from '@/utils/requestApi'
// Utils
import { isNil } from 'lodash'

/**
 * Get all dishes by UID category
 *
 * @param {Array} id - UID category
 */
export const getEveryDishesByCategoryId = async (id) => {
  const result = await doRequest({
    method: 'get',
    url: `v1/dish/dishes/category/${id}`
  })

  return result.data
}
/**
 * Create new dish
 *
 * @param {Object} data - dish data
 */
export const createDish = async (data, id = null) => {
  const result = await doRequest({
    method: 'post',
    url: isNil(id) ? 'v1/dish' : `v1/dish/${id}`,
    data: {
      data
    }
  })

  return result.data
}
/**
 * Update dish in database
 *
 * @param {Object} data - dish data
 */
export const updateDishById = async (data) => {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/dish/${id}`,
    data: {
      data: params
    }
  })

  return result.data
}

/**
 * Get dish by UID
 *
 * @param {string} id - UID dish in database
 */
export async function getDishById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/dish/${id}`
  })

  return result.data
}

/**
 * Remove the dish by id
 *
 * @param {string} id - UID dish in database
 * @param {string} categoryId - UID category in database
 */
export const deleteDishById = async (id, categoryId) => {
  const result = await doRequest({
    method: 'delete',
    url: `v1/dish/${id}/${categoryId}`
  })

  return result.data
}
/**
 * Remove all dishes by ids
 *
 * @param {string} categoryId - UID category
 */
export async function deleteEveryDishesByCategoryId(categoryId) {
  const result = await doRequest({
    method: 'delete',
    url: `v1/dish/dishes/category/${categoryId}`
  })

  return result.data
}
/**
 * Set every dish price to NULL when
 * the category parent (carta) becomes to "menu"
 *
 * @param {string} categoryId - UID category
 */
export const setEveryPricesToNullByCategoryId = async (categoryId) => {
  const result = await doRequest({
    method: 'patch',
    url: `v1/dish/prices-to-null/category/${categoryId}`
  })

  return result.data
}
/**
 * Remove every deleted ration from the dishes
 *
 * @param {Array} rationsIds - UID rations
 * @param {Array} companyId - UID company
 */
export const deleteDishPricesByRationIdsAndCompanyId = async (rationsIds, companyId) => {
  const result = await doRequest({
    method: 'delete',
    url: `v1/dish/prices-to-delete/rations-company`,
    data: {
      rationsIds,
      companyId
    }
  })

  return result.data
}
