// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Obtenemos los campos a traducir por modelo
 *
 * @param {Object} model - firebase model
 * @return {array | boolean}
 */
export async function getFieldsToTranslateByModel(model) {
  const result = await doRequest({
    method: 'get',
    url: `v1/translate/model/fields/${model}`
  })

  return result.data
}

/**
 * Obtenemos el atributo del modelo donde almacenados la
 * traducción mediante el modelo
 *
 * @param {Object} model - firebase model
 * @return {array | boolean}
 */
export async function getTranslationsAttributeByModel(model) {
  const result = await doRequest({
    method: 'get',
    url: `v1/translate/model/translation-attribute/${model}`
  })

  return result.data
}

/**
 * Almacenamos las traducciones según modelo e idiomas
 *
 * @param {Object} model - firebase model
 * @param {Object} data - data to save
 * @param {Object} options - opciones de guardado (traducciones...)
 * @return {promise | boolean}
 */
export async function updateTranslationsByModel(model, data, options = {}) {
  const result = await doRequest({
    method: 'patch',
    url: `v1/translate/model/${model}`,
    data: {
      data,
      options
    }
  })

  return result.data
}

/**
 * Actualizamos todos los modelos asociados a un establecimiento
 *
 * @param {String} type - tipo de asociación (place o company)
 * @param {String} id - UID del tipo de asociación
 * @param {Object} options - opciones de guardado (traducciones...)
 */
export async function updateEveryTranslations(type, id, options) {
  const result = await doRequest({
    method: 'patch',
    url: 'v1/translate/every',
    data: {
      id,
      options,
      type
    }
  })

  return result.data
}
