// Filters
import { formatPrice } from '@/filters'

export default {
  name: 'CurrencyPosition',
  props: {
    price: {
      type: [Number, String],
      default: null
    },
    currency: {
      type: String,
      default: 'EUR'
    }
  },
  computed: {
    /**
     * Get the current format number by country and coin
     *
     * @return {string} - return number format prince
     */
    formatCurrencyPrice() {
      return formatPrice(this.price, this.currency || 'EUR')
    }
  }
}
