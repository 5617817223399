// Components
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Services
import {
  updateTranslationsByModel,
  getFieldsToTranslateByModel,
  getTranslationsAttributeByModel
} from '@/services/translate'
// Vuex
import { mapState } from 'vuex'
// Utils
import { getPathImage } from '@/utils'
import { get, set } from 'lodash'
// Filters
import { sanitizeHtmlContent } from '@/filters'

export default {
  name: 'TranslateForm',
  components: { FormButtons, VuetifyContentLoading },
  mixins: [addonsMixin, formMixin, uiMixin],
  props: {
    // Idiomas adicionales para traducir
    additionalLanguages: {
      type: Array,
      default() {
        return []
      }
    },
    // Idioma por defecto
    defaultLanguage: {
      type: String,
      default: null
    },
    // UID del modelo a quién relacionar la traducción
    id: {
      type: String,
      default: null
    },
    // Modelo a quién relacionar la traducción
    model: {
      type: String,
      default: null
    },
    // Variables del componente padre "VuetifyTabs"
    index: {
      // Indice que ocupo dentro del componente
      type: Number,
      default: 0
    },
    itemsData: {
      // Datos que se comparten entre pestañas del componente  (VuetifyTabs)
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // Model
      modelData: null,
      // Form
      formFields: {},
      // Others
      availableLanguages: null, // Todos los idiomas disponibles
      fieldsToTranslate: null, // Campos del modelo a traducir
      processingRequest: true,
      selectedLanguage: null, // Idioma seleccionado
      translationsAttribute: null // Atributo del modelo donde se almacena las traducciones
    }
  },
  computed: {
    ...mapState('config', ['languages'])
  },
  watch: {
    itemsData: {
      async handler(value) {
        try {
          // show loading
          this.processingRequest = true
          // Datos de la primera pestaña (vuetifyTabs)
          this.modelData = get(value, '0.data', null)
          // Idiomas disponibles
          this.availableLanguages = this.languages.filter((lang) => {
            return this.additionalLanguages.indexOf(lang.id) > -1
          })
          // Idioma seleccionado
          this.selectedLanguage = get(this.availableLanguages, '0.id', null)
          // Campos a traducir
          this.fieldsToTranslate = await getFieldsToTranslateByModel(this.model)
          // Atributo donde se almacerá la traducción
          this.translationsAttribute = await getTranslationsAttributeByModel(this.model)
          // generamos los campos del formulario
          await this.setFormFieldsValues()
        } catch (error) {
          // Show error
          this.modifyAppAlert({
            text: error.message,
            type: 'error',
            visible: true
          })
        } finally {
          // hide loading
          this.processingRequest = false
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.hideDialog()
    },
    /**
     * Establecemos los campos del formulario y sus valores
     *
     * @return {Object} - form fields generated
     */
    async setFormFieldsValues() {
      // Generamos los distintos campos a traducir.
      // Si el campo originario está vacío, el campo
      // relacionado, a traducir, no se mostrará
      if (
        Array.isArray(this.availableLanguages) &&
        this.availableLanguages.length > 0 &&
        Array.isArray(this.fieldsToTranslate) &&
        this.fieldsToTranslate.length > 0
      ) {
        // Repasamos cada idioma
        this.formFields = this.availableLanguages.reduce((accLangs, lang) => {
          // Repasamos cada uno de los campos
          const everyFields = this.fieldsToTranslate.reduce((accFields, field) => {
            accFields[field.id] = {
              id: field.id,
              label: field.label,
              type: field.type,
              value: get(
                this.modelData,
                `${this.translationsAttribute}.${lang.id}.${field.id}`,
                null
              )
            }
            return accFields
          }, {})

          // Incluimos campos por cada una de las pestañas de idiomas
          set(accLangs, lang.id, everyFields)

          return accLangs
        }, {})
      }
    },
    /**
     * Get path image
     *
     * @return {string} - image url
     */
    getFlagImage(path, brand = false) {
      return getPathImage(path, brand)
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Parseamos los datos antes de salvar
      const parsedFormFields = Object.entries(this.formFields).reduce((accLangs, lang) => {
        const langId = lang[0]
        const langValues = Object.entries(lang[1])
        const langFields = langValues.reduce((accFields, field) => {
          accFields[field[1].id] = sanitizeHtmlContent(field[1].value, []).replace(/&amp;/g, '&') // Solución adHoc para el problema con los "&"
          return accFields
        }, {})

        accLangs[langId] = langFields

        return accLangs
      }, {})

      // Almacenamos datos en el modelo indicado
      await updateTranslationsByModel(
        this.model,
        {
          id: this.modelData.id,
          [this.translationsAttribute]: parsedFormFields
        },
        {
          additionalLanguages: this.additionalLanguages,
          defaultLanguage: this.defaultLanguage
        }
      )

      // Información resultados acción de salvado
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })

      // Emitimos evento general para que cualquier lista
      // a la escucha, recargue su listado
      this.$root.$emit('reload-list')
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    }
  }
}
