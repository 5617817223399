// Utils
import { get } from 'lodash'
// Vuex
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('company', ['companyData']),
    /**
     * Obtiene las suscripciones de la compañía
     *
     * @return {Array}
     */
    companySubscriptions() {
      const subscriptions = get(this.companyData, 'subscriptions', [])
      return subscriptions.reduce((sumSubs, sub) => {
        const id = get(sub, 'subscriptable.id', null)
        if (id) {
          sumSubs.push(id)
        }
        return sumSubs
      }, [])
    },
    /**
     * Obtiene las configuraciones de los "addons"
     * que los usuarios han generado en "companyData"
     *
     * @return {Object}
     */
    companyAddonsSetupByUser() {
      const addOnConfigs = get(this.companyData, 'addOnConfigs', [])

      return addOnConfigs.reduce((sumAddOns, addOn) => {
        sumAddOns[addOn.id] = { ...(addOn.configFields || {}) }
        return sumAddOns
      }, {})
    },
    /**
     * Obtiene los "addons" que las empresas y
     * los establecimientos tienen comprados
     *
     * @return {Array}
     */
    acquiredAddons() {
      return [...this.companySubscriptions, ...Object.keys(this.companyAddonsSetupByUser)]
    }
  }
}
